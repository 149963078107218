<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 3.33464C6.31818 3.33464 3.33341 6.3194 3.33341 10.0013C3.33341 13.6832 6.31818 16.668 10.0001 16.668C13.682 16.668 16.6667 13.6832 16.6667 10.0013C16.6667 6.3194 13.682 3.33464 10.0001 3.33464ZM1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.04123 6.4664C9.40056 5.62058 10.5994 5.62058 10.9587 6.4664L11.6077 7.99408L13.2112 8.12838C14.1185 8.20437 14.4985 9.32585 13.8243 9.93775L12.5981 11.0507L12.9995 12.8991C13.2023 13.8329 12.1456 14.5242 11.3712 13.9642L9.99996 12.9728L8.62875 13.9642C7.85434 14.5242 6.79767 13.8329 7.00046 12.8991L7.40183 11.0507L6.17559 9.93776C5.50139 9.32586 5.88142 8.20437 6.78872 8.12838L8.39222 7.99408L9.04123 6.4664Z" />
  </svg>
</template>

<script>
export default {
  name: 'StarCircle',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
