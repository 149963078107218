<template>
  <div>
    <div v-show="!showCredits" class="flex flex-col p-4 addFilesModal">
      <div class="text-3xl font-semibold font-h text-primary">
        Import Audio
      </div>

      <div class="flex flex-grow mt-4 border rounded border-primary-300">
        <div class="border-r border-primary-300 leftSection">
          <div
            v-for="(action, i) in actionList"
            :key="i"
            class="w-full text-sm border-b cursor-pointer border-primary-300 text-primary-700 last:border-0"
            :class="{'font-semibold text-primary bg-primary-300': selectedOption === action.name}"
            @click="changeView(action,i)"
          >
            <div class="flex items-center pl-4 h-11">
              {{ action.label }}
            </div>
          </div>
        </div>

        <transition name="component-fade" mode="out-in">
          <SpextDocs
            v-if="selectedOption === 'spextdocs'"
          />
          <Sounds v-else-if="selectedOption === 'sounds'" />
          <UploadMedia v-else-if="selectedOption === 'uploadmedia'" />
        </transition>
      </div>
    </div>

    <div v-show="showCredits" class="creditsModal">
       <div  class="flex flex-grow">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" src="../../../../../assets/images/credits/1.png">

        <div class="flex flex-col justify-between w-full p-6">

          <div class="mt-4 text-xs text-primary-800">
            <div class="flex items-start mb-3">
              <StarCircleIcon class="mr-2 text-yellow starIcon" />
              <div>
               Credit for the sounds goes to
               <br/>
               <a href="https://freesound.org/" target="_blank" rel="noopener" class="text-blue hover:text-blue">Freesound.org</a>. They are
                licensed under <a href="https://creativecommons.org/publicdomain/zero/1.0/" target="_blank" rel="noopener" class="text-blue hover:text-blue">“Creative
                Commons 0”</a>.
              </div>
            </div>
            <div class="flex items-start mb-3">
              <i class="mr-3 text-base text-green far fa-check-circle" />
              <div>
                <b>What you can do with the sounds:</b>
                <br/>
                You can use them commercially for your projects without attribution.
              </div>
            </div>
            <div class="flex items-start">
              <i class="mr-3 text-base text-red far fa-times-circle" />
              <div>
                <b>What you cannot do:</b>
                <br/>
                Claim you are the creator of these sounds.
              </div>
            </div>
          </div>

          <div class="flex items-center justify-end">
            <Button
              size="small"
              @click="closeFreeCredits"
              classname="mr-2.5"
            >
              Back
            </Button>
          </div>
        </div>
       </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '@/services/store'
import Button from '@/components/base/buttons/Button.vue'
import StarCircleIcon from '@/components/base/icons/StarCircle.vue'

export default {
  name: 'AddFiles',
  actionList: [],
  components: {
    Button,
    StarCircleIcon,
    SpextDocs: () => import(/* webpackChunkName: "AddFileSpextDoc" */'./SpextDocs/SpextDocs'),
    Sounds: () => import(/* webpackChunkName: "AddFileSounds" */'./Sounds/Sounds'),
    UploadMedia: () => import(/* webpackChunkName: "AddFileUploadMedia" */'./UploadMedia/UploadMedia')
  },
  data () {
    return { selectedOption: '' }
  },
  created () {
    this.selectedOption = this.actionList[0].name
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    changeView (option, index) {
      this.selectedOption = option.name
      store.commit('modal/clearSelectedFile')
    },
    addFile () {
      store.dispatch('modal/addFile', this.isReplacing)
    },
    closeFreeCredits () {
      store.dispatch('modal/setShowCredits', false)
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    }),
    isReplacing: function() {
      return this.modalProps.isReplacing
    },
    showCredits () {
      return store.state.modal.showCredits
    },
    soundMode: function() {
      return this.modalProps.soundMode
    },
    selectedFile () {
      return store.state.modal.selectedFile
    },
    currentPath () {
      return this.$route.name
    },
    actionList () {
      let list = []
      if (this.currentPath === 'HomeProse') {
        // if user has selected something, then he wants to add in background
        if (!window.getSelection().isCollapsed || this.soundMode) {
          list = ['sounds', 'uploadmedia']
        } else {
          list = ['spextdocs', 'sounds', 'uploadmedia']
        }
      } else {
        list = ['uploadmedia']
      }
      let res = []
      list.forEach(function (el) {
        // tabs inside the add file popover
        switch (el) {
          case 'sounds':
            res.push({
              label: 'Sounds',
              name: 'sounds',
              path: '/addfile/sounds'
            })
            break
          case 'spextdocs':
            res.push({
              label: 'Spext Docs',
              name: 'spextdocs',
              path: '/addfile/spextdocs'
            })
            break
          case 'uploadmedia':
            res.push({
              label: 'Upload Music',
              name: 'uploadmedia',
              path: '/addfile/uploadmedia'
            })
            break
        }
      })
      return res
    }
  }
}
</script>

<style lang="scss">
.creditsModal {
  width: 560px;
  height: 272px;
}

.leftImg  {
  height: 272px;
}

.starIcon {
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;

}

.addFilesModal {
  width: 780px;
  height: 520px;
}

.leftSection {
  min-width: 150px;
  max-width: 150px;
}

.rightSection {
  min-width: calc(100% - 150px);
  max-width: calc(100% - 150px);
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.2s ease-in-out;
  opacity: 1;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
  // transform: translateX(30px);
}
</style>
