<template>
  <Modal closePosition="top-6 right-3.5">
    <AddFiles />
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import AddFiles from '../AddFiles/AddFiles.vue'
import Modal from '@/components/base/dialogs/Modal.vue'

export default {
  name: 'AddFilePopover',
  components: {
    AddFiles,
    Modal
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
